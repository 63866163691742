import { IAddress } from './address';
import { CurrencyEnum } from './supplier';

export enum EnquiryTypeEnum {
    HomePage = 1,
    DesignServices = 2,
}

export enum EnquiryStatusEnum {
    Pending = 1,
    Resolved = 2,
}

export enum DesignServicePackageEnum {
    DesignTouchUps = 1,
    PremiumPackage = 2,
    CreativeSuite = 3,
}

export interface IEnquiryListItem {
    id: string;
    name: string;
    subject: string;
    message: string;
    status: EnquiryStatusEnum;
    type: EnquiryTypeEnum;
    createdAt: string;
    designPackage?: DesignServicePackageEnum;
}

export interface IEnquiry {
    id: string;
    email: string;
    name: string;
    phoneNumber: string;
    companyAccountId?: string;
    companyName?: string;
    subject: string;
    message: string;
    status: EnquiryStatusEnum;
    type: EnquiryTypeEnum;
    createdAt: string;
    address?: IAddress;
    designPackage?: DesignServicePackageEnum;
    currency?: CurrencyEnum;
    totalAmount?: number;
}
