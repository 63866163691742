import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import DropdownButton from 'components/atoms/DropdownButton';
import DropdownButtonForActions from 'components/atoms/DropdownButtonForActions';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import PageHeader from 'components/atoms/PageHeader';
import StatusBadge from 'components/atoms/StatusBadge';
import Text from 'components/atoms/Text';
import { BadgeVariant } from 'components/atoms/variants/BadgeVariant';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import AdminLayout from 'components/Layout/AdminLayout';
import ConfirmationModal from 'components/molecules/ConfirmationModal';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';
import OrderSelectors from 'redux/slices/order/Selectors';

import media from 'lib/Breakpoints';
import NavActions from 'lib/NavActions';
import { IAddress } from 'entities/address';
import { AuthRoleEnum } from 'entities/auth';
import { IsPartiallyPaidEnum, OrderPaymentMethodEnum, OrderPaymentStatusEnum, OrderPaymentTermEnum, OrderStatusEnum } from 'entities/order';
import { CurrencyEnum } from 'entities/supplier';

import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmation.svg';

import AttachmentUpload from './components/AttachmentUpload';
import AuditLog from './components/AuditLog';
import CommentForm from './components/CommentForm';
import ConvertedOrder from './components/ConvertedOrder';
import OrderDetails from './components/OrderDetails';
import PaymentDetails from './components/PaymentDetails';
import PaymentTerm from './components/PaymentTerm';
import ProcessOrder from './components/ProcessOrder';
import ProductDetails from './components/ProductDetails';
import ProductListTable from './components/ProductListTable';

const ViewOrder = (): JSX.Element => {
    const params = useParams();
    const orderId = params.id;

    const userInfo = useAppSelector(AuthSelectors.getUserInfo);

    const orderDetails = useAppSelector(OrderSelectors.getOrderDetailsData);
    const orderDetailsLoading = useAppSelector(OrderSelectors.getOrderDetailsAttempting);
    const orderDetailsError = useAppSelector(OrderSelectors.getOrderDetailsError);

    const commentLoading = useAppSelector(OrderSelectors.getOrderCommentAttempting);
    const commentError = useAppSelector(OrderSelectors.getOrderCommentError);
    const orderComment = useAppSelector(OrderSelectors.getOrderCommentData);

    const commentData = orderComment.data;

    const timeline = useAppSelector(OrderSelectors.getTimelineData);
    const timelineLoading = useAppSelector(OrderSelectors.getTimelineAttempting);
    const timelineError = useAppSelector(OrderSelectors.getTimelineError);

    const shipmentTracking = useAppSelector(OrderSelectors.orderGetAllShipmentTrackingData);
    const shipmentTrackingLoading = useAppSelector(OrderSelectors.orderGetAllShipmentTrackingAttempting);
    const shipmentTrackingError = useAppSelector(OrderSelectors.orderGetAllShipmentTrackingError);

    const exportOrderLoading = useAppSelector(OrderSelectors.exportOrderAttempting);
    const updateOrderStatusLoading = useAppSelector(OrderSelectors.orderUpdateOrderStatusAttempting);
    const updateProcessOrderLoading = useAppSelector(OrderSelectors.updateProcessOrderAttempting);
    const updatePaymentTermsLoading = useAppSelector(OrderSelectors.updatePaymentTermsAttempting);
    const uploadClientPoLoading = useAppSelector(OrderSelectors.orderUploadClientPoAttempting);
    const uploadPaymentProofLoading = useAppSelector(OrderSelectors.orderUploadPaymentProofAttempting);
    const uploadOrderAttachmentLoading = useAppSelector(OrderSelectors.orderUploadOrderAttachmentAttempting);
    const updateOrderDetailsLoading = useAppSelector(OrderSelectors.updateOrderAttempting);
    const convertOrderStatusToUnpaidLoading = useAppSelector(OrderSelectors.orderConvertOrderStatusToUnpaidAttempting);

    const updateOrderStatusError = useAppSelector(OrderSelectors.orderUpdateOrderStatusError);
    const updateProcessOrderError = useAppSelector(OrderSelectors.updateProcessOrderError);
    const updatePaymentTermsError = useAppSelector(OrderSelectors.updatePaymentTermsError);
    const uploadClientPoError = useAppSelector(OrderSelectors.orderUploadClientPoError);
    const uploadPaymentProofError = useAppSelector(OrderSelectors.orderUploadPaymentProofError);
    const uploadOrderAttachmentError = useAppSelector(OrderSelectors.orderUploadOrderAttachmentError);
    const updateOrderDetailsError = useAppSelector(OrderSelectors.updateOrderError);
    const convertOrderStatusToPaidError = useAppSelector(OrderSelectors.orderConvertOrderStatusToPaidError);

    const convertOrderStatusToUnpaidModalIsOpen = useAppSelector(OrderSelectors.convertOrderStatusToUnpaidModalIsOpen);

    const dispatch = useAppDispatch();

    const getOrderDetails = () => {
        if (orderId) {
            dispatch(Actions.orderGetOrderDetailsAttempt({ id: orderId }));
            dispatch(Actions.orderGetOrderCommentAttempt({ orderId, index: 0 }));
            dispatch(Actions.orderGetTimelineAttempt({ id: orderId }));
            dispatch(Actions.orderGetAllShipmentTrackingAttempt({ orderId }));
            dispatch(Actions.orderGetPaymentLinkAttempt({ orderId }));
        }
    };

    useEffect(() => {
        getOrderDetails();

        return () => {
            dispatch(Actions.orderResetOrderDetails());
        };
    }, []);

    const orderStatusOption = [{
        label: 'Pending',
        value: OrderStatusEnum.Pending,
    }, {
        label: 'In Production',
        value: OrderStatusEnum.InProduction,
    }, {
        label: 'Packing',
        value: OrderStatusEnum.Packing,
    }, {
        label: 'Shipped',
        value: OrderStatusEnum.Shipped,
    }, {
        label: 'Delivered',
        value: OrderStatusEnum.Delivered,
    }, {
        label: 'Cancelled',
        value: OrderStatusEnum.Cancelled,
    },
    ];
    const actionsOption = [
        {
            label: 'Send Payment link',
            onClick: () => dispatch(Actions.orderSendPaymentLinkAttempt({ orderId: orderId as string })),
            isHidden: orderDetails?.paymentStatus === OrderPaymentStatusEnum.Paid,
        }, {
            label: 'Payment Link',
            onClick: () => NavActions.navToPaymentOrder(orderId as string),
            isHidden: orderDetails?.paymentStatus === OrderPaymentStatusEnum.Paid,
        }, {
            label: 'Convert to Purchase Order',
            onClick: () => NavActions.navToCreatePurchaseOrder(),
        }, {
            label: 'Convert to unpaid',
            onClick: () => dispatch(Actions.orderSetConvertOrderStatusToUnpaidModalIsOpen(true)),
            isHidden: orderDetails?.paymentStatus !== OrderPaymentStatusEnum.Paid,
        },
    ];
    const orderStatusVariant = (status: OrderStatusEnum) => {
        switch (status) {
            case OrderStatusEnum.Pending:
                return BadgeVariant.Pending;
            case OrderStatusEnum.Cancelled:
                return BadgeVariant.Cancelled;
            case OrderStatusEnum.InProduction:
                return BadgeVariant.SentToProduction;
            case OrderStatusEnum.Packing:
                return BadgeVariant.Packing;
            case OrderStatusEnum.Shipped:
                return BadgeVariant.Shipped;
            case OrderStatusEnum.Delivered:
                return BadgeVariant.Delivered;
            default:
                return BadgeVariant.Pending;
        }
    };
    const paymentStatusVariant = (status: OrderPaymentStatusEnum) => {
        switch (status) {
            case OrderPaymentStatusEnum.POReceived:
                return BadgeVariant.POReceived;
            case OrderPaymentStatusEnum.Paid:
                return BadgeVariant.Paid;
            case OrderPaymentStatusEnum.PartiallyPaid:
                return BadgeVariant.PartiallyPaid;
            case OrderPaymentStatusEnum.Unpaid:
                return BadgeVariant.Unpaid;
            default:
                return BadgeVariant.Unpaid;
        }
    };
    const onChangeStatus = (newStatus: string | number) => {
        if (orderDetails?.status === newStatus) return;

        dispatch(Actions.orderUpdateOrderStatusAttempt({
            id: orderId as string,
            status: newStatus as number,
        }));
    };
    const onDownload = () => {
        dispatch(Actions.orderExportOrderAttempt({ id: orderId as string }));
    };
    const onUploadAttachment = (folder: string, file: File[]) => {
        dispatch(Actions.orderUploadOrderAttachmentAttempt({
            orderId: orderId as string,
            folder,
            attachments: file,
        }));
    };
    const onDeleteAttachment = (folder: string, file: string) => {
        dispatch(Actions.orderDeleteOrderAttachmentAttempt({
            orderId: orderId as string,
            folder,
            filePath: file,
        }));
    };
    const onDeleteAttachmentFolder = (folder: string) => {
        dispatch(Actions.orderDeleteOrderAttachmentFolderAttempt({
            orderId: orderId as string,
            folder,
        }));
    };

    const onUploadCustomerPoClicked = (file: File) => {
        dispatch(Actions.orderUploadClientPoAttempt({ orderId: orderId as string, receivedPurchaseOrder: file }));
    };

    const onDeleteCustomerPoClicked = (url: string) => {
        dispatch(Actions.orderDeleteClientPoAttempt({ orderId: orderId as string, filePath: url }));
    };

    const onUploadPaymentClicked = (file: File, paymentType: IsPartiallyPaidEnum, paymentAmount: number) => {
        dispatch(Actions.orderUploadPaymentProofAttempt({
            orderId: orderId as string,
            paymentProof: file,
            isPartiallyPaid: paymentType,
            paymentAmount,
        }));
    };

    const onDeletePaymentClicked = (paymentProofId: string) => {
        dispatch(Actions.orderDeletePaymentProofAttempt({ orderId: orderId as string, paymentProofId }));
    };

    const onAddComment = (comment: string) => {
        dispatch(Actions.orderCreateOrderCommentAttempt({
            orderId: orderId as string,
            text: comment,
        }));
    };
    const onDeleteComment = (id: string) => {
        dispatch(Actions.orderDeleteOrderCommentAttempt({
            orderId: orderId as string,
            id,
        }));
    };
    const onEditComment = (id: string, text: string) => {
        dispatch(Actions.orderUpdateOrderCommentAttempt({
            orderId: orderId as string,
            id,
            text,
        }));
    };
    const onUpdateProcessOrder = (projectName: string, projectDueDate: string) => {
        dispatch(Actions.orderUpdateProcessOrderAttempt({
            orderId: orderId as string,
            projectName,
            projectDueDate,
        }));
    };
    const onUpdatePaymentTerms = (paymentTerm: OrderPaymentTermEnum, paymentDueDate: string) => {
        dispatch(Actions.orderUpdatePaymentTermsAttempt({
            orderId: orderId as string,
            paymentTerm,
            paymentDueDate,
        }));
    };

    const onEditShippingAddress = (shippingAddress: IAddress) => {
        dispatch(Actions.orderUpdateOrderDetailsAttempt({
            shippingAddress,
            id: orderId as string,
            clientId: orderDetails?.clientId ?? '',
            companyAccountId: orderDetails?.companyAccountId ?? '',
        }));
    };

    const onEditBillingAddress = (billingAddress: IAddress) => {
        dispatch(Actions.orderUpdateOrderDetailsAttempt({
            billingAddress,
            id: orderId as string,
            clientId: orderDetails?.clientId ?? '',
            companyAccountId: orderDetails?.companyAccountId ?? '',
        }));
    };

    const onConvertOrderStatusToUnpaidCancelled = () => {
        dispatch(Actions.orderSetConvertOrderStatusToUnpaidModalIsOpen(false));
    };

    const onConvertOrderStatusToUnpaid = () => {
        dispatch(Actions.orderConvertOrderStatusToUnpaidAttempt({ orderId: orderId as string }));
    };

    const renderOrderActions = () => {
        if (userInfo?.role === AuthRoleEnum.Supplier) return null;
        return (
            <>
                <DropdownButtonForActions
                    options={actionsOption}
                />
                <DropdownButton
                    options={orderStatusOption}
                    value={orderDetails?.status}
                    onSelect={onChangeStatus}
                    loading={updateOrderStatusLoading}
                    isOrderStatus
                />
            </>
        );
    };
    const renderBody = () => {
        return (
            <>
                <HeaderContainer>
                    <Text style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Products</Text>
                    <div id='actions'>
                        {renderOrderActions()}
                        <Button
                            variant={ButtonVariant.secondary}
                            label='Download'
                            loading={exportOrderLoading}
                            onClick={onDownload}
                        />
                    </div>
                </HeaderContainer>
                <ContentContainer>
                    <div id='left'>
                        <ProductListTable
                            orderDetails={orderDetails}
                            loading={orderDetailsLoading}
                            error={orderDetailsError}
                        />
                        <ProductDetails
                            orderDetails={orderDetails}
                            loading={orderDetailsLoading}
                            error={orderDetailsError}
                        />
                        <AttachmentUpload
                            orderDetails={orderDetails}
                            onUploadAttachment={onUploadAttachment}
                            onDeleteAttachment={onDeleteAttachment}
                            onDeleteAttachmentFolder={onDeleteAttachmentFolder}
                            loading={uploadOrderAttachmentLoading}
                            error={uploadOrderAttachmentError}
                        />
                        <div id='processLeft'>
                            <ProcessOrder
                                projectName={orderDetails?.projectName ?? ''}
                                projectDueDate={orderDetails?.projectDueDate ?? dayjs(new Date()).toISOString()}
                                consultantName={orderDetails?.personInCharge}
                                loading={orderDetailsLoading}
                                error={orderDetailsError}
                                onUpdateProcessOrder={onUpdateProcessOrder}
                                updateLoading={updateProcessOrderLoading}
                            />
                            <PaymentTerm
                                paymentTerm={orderDetails?.paymentTerm}
                                paymentDueDate={orderDetails?.paymentDueDate}
                                loading={orderDetailsLoading}
                                error={orderDetailsError}
                                onUpdatePaymentTerms={onUpdatePaymentTerms}
                                updateLoading={updatePaymentTermsLoading}
                            />
                        </div>
                        <PaymentDetails
                            orderDetails={orderDetails}
                            allShipmentTracking={shipmentTracking}
                            onUploadCustomerPoClicked={onUploadCustomerPoClicked}
                            onDeleteCustomerPoClicked={onDeleteCustomerPoClicked}
                            onUploadPaymentClicked={onUploadPaymentClicked}
                            onDeletePaymentClicked={onDeletePaymentClicked}
                            onAddTrackingClicked={() => NavActions.navToAddShipmentTracking(orderId as string)}
                            onUploadCustomerPoLoading={uploadClientPoLoading}
                            onUploadPaymentLoading={uploadPaymentProofLoading}
                        />
                        <CommentForm
                            comment={commentData}
                            onAddComment={onAddComment}
                            onDeleteComment={onDeleteComment}
                            onEditComment={onEditComment}
                        />
                        <div id='productDetails'>
                            <AuditLog
                                currency={orderDetails?.currency ?? CurrencyEnum.MalaysianRinggit}
                                timelines={timeline}
                                loading={timelineLoading}
                                error={timelineError}
                            />
                        </div>
                        <div id='orderDetails'>
                            <AuditLog
                                currency={orderDetails?.currency ?? CurrencyEnum.MalaysianRinggit}
                                timelines={timeline}
                                loading={timelineLoading}
                                error={timelineError}
                            />
                            <OrderDetails
                                // fitWidth
                                orderDetails={orderDetails}
                                onViewQuotation={() => NavActions.navToQuotationDetails(orderDetails?.quotationId as string)}
                                onViewCustomer={() => NavActions.navToCustomerEdit(orderDetails?.clientId as string)}
                                onEditShippingAddress={onEditShippingAddress}
                                onEditBillingAddress={onEditBillingAddress}
                                updateAddressLoading={updateOrderDetailsLoading}
                                loading={orderDetailsLoading}
                                error={orderDetailsError}
                            />

                            <ConvertedOrder
                                orderDetails={orderDetails}
                                loading={orderDetailsLoading}
                                error={orderDetailsError}
                            />
                        </div>
                    </div>
                    <div id='right'>
                        <div id='processRight'>
                            <ProcessOrder
                                projectName={orderDetails?.projectName ?? ''}
                                projectDueDate={orderDetails?.projectDueDate ?? dayjs(new Date()).toISOString()}
                                consultantName={orderDetails?.personInCharge}
                                loading={orderDetailsLoading}
                                error={orderDetailsError}
                                onUpdateProcessOrder={onUpdateProcessOrder}
                                updateLoading={updateProcessOrderLoading}
                            />
                        </div>
                        <div id='processRight'>
                            <PaymentTerm
                                paymentTerm={orderDetails?.paymentTerm}
                                paymentDueDate={orderDetails?.paymentDueDate}
                                loading={orderDetailsLoading}
                                error={orderDetailsError}
                                onUpdatePaymentTerms={onUpdatePaymentTerms}
                                updateLoading={updatePaymentTermsLoading}
                            />
                        </div>
                        <div id='processRight'>
                            <OrderDetails
                                orderDetails={orderDetails}
                                onViewQuotation={() => NavActions.navToQuotationDetails(orderDetails?.quotationId as string)}
                                onViewCustomer={() => NavActions.navToCustomerEdit(orderDetails?.clientId as string)}
                                onEditShippingAddress={onEditShippingAddress}
                                onEditBillingAddress={onEditBillingAddress}
                                updateAddressLoading={updateOrderDetailsLoading}
                                loading={orderDetailsLoading}
                                error={orderDetailsError}
                            />
                        </div>
                        <div id='processRight'>
                            <ConvertedOrder
                                orderDetails={orderDetails}
                                loading={orderDetailsLoading}
                                error={orderDetailsError}
                            />
                        </div>
                    </div>
                </ContentContainer>
            </>
        );
    };

    return (
        <AdminLayout>
            <PageHeader withBackButton>
                <PageTitle>
                    {`Order ${orderDetails?.orderNo}`}
                    <span>
                        <StatusBadge variant={paymentStatusVariant(orderDetails?.paymentStatus ?? OrderPaymentStatusEnum.Unpaid)} />
                    </span>
                    <span>
                        <StatusBadge variant={orderStatusVariant(orderDetails?.status ?? OrderStatusEnum.Pending)} />
                    </span>
                </PageTitle>
            </PageHeader>
            {renderBody()}
            <ConfirmationModal
                isOpen={convertOrderStatusToUnpaidModalIsOpen}
                icon={<ConfirmationIcon />}
                title='Are you sure you want to mark this order as unpaid?'
                text='you cannot undo this action.'
                onCancel={onConvertOrderStatusToUnpaidCancelled}
                onConfirm={onConvertOrderStatusToUnpaid}
                cancelText='Cancel'
                confirmText='Confirm'
                confirmButtonVariant={ButtonVariant.warning}
                loading={convertOrderStatusToUnpaidLoading}
            // error={convertOrderStatusToPaidError}
            />
        </AdminLayout>
    );
};
const PageTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;
const HeaderContainer = styled.div<{ noActions?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-block: 1.25rem;

    #actions {
        width: ${props => (props.noActions ? '20%' : '40%')};
        display: flex;
        gap: 1rem;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    gap: 1.25rem;

    > div {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    @media (max-width: 1640px) {
        flex-direction: column;
    }
    #left {
        width: 70%;

        @media (max-width: 1640px) {
            width: 100%;
        }
    }

    #right {
        width: 30%;
        @media (max-width: 1640px) {
            width: 100%;
        }
    }


    #productDetails{
        display: flex;

        @media (max-width: 1640px){
            display: none;
        }
    }
    #orderDetails{
        display: none;

        @media (max-width: 1640px){
            display: flex;
            gap: 1rem;
        }

    }

    #processLeft{
        display: none;
        gap: 1rem;

        @media (max-width: 1640px) {
            display: flex;
        }
    }

    #processRight{
        display: flex;

        @media (max-width: 1640px) {
            display: none;
        }
    }
`;

const LoadingContainer = styled.div`
    display: flex;

    background-color: white;
    border-radius: 15px;

    justify-content: center;
    align-items: center;

    width: 100%;
    height: 240px;
`;

export default ViewOrder;
