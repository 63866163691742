import { IAddress } from './address';
import { TaxRateEnum } from './products';
import { IQuotationProductQuantity, QuotationShippingMethodEnum } from './quotations';
import { CurrencyEnum } from './supplier';

export enum OrderStatusEnum {
    Pending = 1,
    InProduction = 2,
    Packing = 3,
    Shipped = 4,
    Delivered = 5,
    Cancelled = 6,
}

export enum OrderPaymentStatusEnum {
    Unpaid = 1,
    POReceived = 2,
    Paid = 3,
    PartiallyPaid = 4,
}

export enum IsPartiallyPaidEnum {
    true = 1,
    false = 2,
}

export enum OrderPaymentMethodEnum {
    CreditCard = 1,
    OnlineBanking = 2,
}

export enum OrderPaymentTermEnum {
    NetFourteenAfterDelivery = 1,
    NetFourteen = 2,
    NetThirty = 3,
    HundredPercentDeposit = 4,
    SixtyPercentDeposit = 5,
    FiftyPercentUponDelivery = 6,
    NA = 7,
}

export enum OrderAuditLogActionTypeEnum {
    OrderCreated = 1,
    StatusUpdate = 2,
    PaymentReceived = 3,
    Shipped = 4,
    PaymentProofRemoved = 5,
    PaymentStatusUpdate = 6,
}

export enum PaymentChannelEnum {
    PaymentLink = 1,
    PaymentProof = 2,
}

export enum ShippingMethodEnum {
    InternationalShipping = 1,
    WestMalaysiaShipping = 2,
    EastMalaysiaShipping = 3,
}

export interface IAdditionalDataForOrderAuditLog {
    paymentAmount?: number;
    paymentMethod?: PaymentChannelEnum;
    shippingMethod?: ShippingMethodEnum;
    statusUpdate?: OrderStatusEnum;
    paymentStatusUpdate?: OrderPaymentStatusEnum
    paymentProofFileName?: string;
}

export interface IAttachment {
    _id: string;
    folder: string;
    attachmentUrl: string[];
    createdBy?: string;
    uploadTimestamp?: string;
}

export interface IPaymentProof {
    _id: string;
    paymentProofMediaUrl: string;
    paymentAmount: number;
    quickbooksPaymentProofId: string;
    quickbooksPaymentProofSyncToken: string;
}
export interface IOrder {
    id: string;
    orderNo: string;
    status: OrderStatusEnum;
    quotationId: string,
    quotationNumber?: string,
    enquiryId?: string;
    enquiryNumber?: string;
    designPackage?: number;
    quickbooksInvoiceId: string,
    personInCharge: string;
    projectName: string;
    projectDueDate: string;
    shippingMethod: QuotationShippingMethodEnum;
    shippingAddress: IAddress;
    paymentMethod: OrderPaymentMethodEnum,
    paymentTerm: OrderPaymentTermEnum,
    paymentDueDate: string;
    paymentStatus: number;
    products: IOrderProduct[];
    customLineProducts: IOrderCustomLineProduct[];
    subtotal: number;
    discountPercent?: number;
    discountFixed?: number;
    tax: number;
    shippingFee: number;
    finalPrice: number;
    note?: string;
    remark?: string;
    createdAt: string;
    companyAccountId: string;
    companyName: string;
    companyAlias: string;
    companyAddress: IAddress;
    companyNumber: string;
    companyRegistrationNumber: string;
    clientId: string;
    clientName: string;
    clientEmail: string;
    clientNumber: string;
    billingAddress: IAddress;
    attachment?: IAttachment[],
    receivedPurchaseOrderUrl?: string,
    paymentProof?: IPaymentProof[],
    stripePaymentLink?: string,
    isPaidWithStripePaymentGateway?: boolean,
    totalWeight?: number,
    currency: CurrencyEnum;
    purchaseOrder: IConvertedOrder[],
    taxType: TaxRateEnum;
}

export interface IConvertedOrder {
    id: string;
    poNumber: string;
    poDate: string;
}
export interface IOrderProductQuantity {
    productPriceId: string;
    size?: string;
    quantity: number;
    quantityPrice: number;
}

export interface IOrderProduct {
    productId: string;
    variantId: string;
    productName?: string;
    productVariant?: {
        fit?: string;
        type?: string;
        sleeve?: string;
        style?: string;
        color?: string;
    },
    quantities: IQuotationProductQuantity[];
    printMethods: {
        side: number,
        printCustomId: string,
        printVariantId: string,
        pricePerUnit: number,
        printMethod?: string,
        block?: string,
        colorCount?: string,
    }[],
    finalQuantity: number;
    subtotal: number;
    discountPercent?: number;
    discountFixed?: number;
    finalProductPrice: number;
    totalPricePerUnit?: number;
    weight?: number;
    remark?: string;
}

export interface IOrderCustomLineProduct {
    productName: string;
    finalQuantity: number;
    finalProductPrice: number;
    discountPercent?: number;
    discountFixed?: number;
    totalPricePerUnit?: number;
    weight?: number;
    remark?: string;
}

export interface IOrderComment {
    _id: string;
    personInCharge: string;
    personInChargeId: string;
    text: string;
    edited: boolean;
    createdAt: string;
}

export interface IShipmentTrackingDetails {
    id: string;
    trackingNo: string;
    trackingUrl: string;
    shippingVendor: string;
    sendOutDate: string;
    numberOfPackages: string;
    products: {
        productId: string;
        productName: string;
    }[];
    remarks?: string;
    companyRegistrationNumber: string;
    companyNumber: string;
    companyEmail: string;
    companyAddress: IAddress;
}

export interface IAuditLog {
    id: string;
    orderId: string;

    action: OrderAuditLogActionTypeEnum;
    data: IAdditionalDataForOrderAuditLog;

    createdAt: Date;
    createdBy: string;
    createdByName: string;
}
