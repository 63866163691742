import React, { useEffect } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import ErrorMessage from 'components/atoms/ErrorMessage';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import PageHeader from 'components/atoms/PageHeader';
import StatusBadge from 'components/atoms/StatusBadge';
import Text from 'components/atoms/Text';
import { BadgeVariant } from 'components/atoms/variants/BadgeVariant';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import AdminLayout from 'components/Layout/AdminLayout';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';
import CustomerSelectors from 'redux/slices/customers/Selectors';
import EnquiresSelectors from 'redux/slices/enquiries/Selectors';

import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';
import { IAddress } from 'entities/address';
import { AuthRoleEnum } from 'entities/auth';
import { DesignServicePackageEnum, EnquiryStatusEnum, EnquiryTypeEnum } from 'entities/enquiries';
import { IProductDetails } from 'entities/products';
import { CurrencyEnum } from 'entities/supplier';

import theme from 'styles/theme';

import ConfirmDeleteModal from './components/ConfirmDeleteModal';
import ConfirmResolveModal from './components/ConfirmResolveModal';

const EnquiryDetails = (): JSX.Element => {
    const { formatPrice, formatCurrency } = Utils.Formatter;

    const [searchParams] = useSearchParams();
    const params = useParams();
    const dispatch = useAppDispatch();

    const userInfo = useAppSelector(AuthSelectors.getUserInfo);
    const loading = useAppSelector(EnquiresSelectors.getEnquiryDetailsAttempting);
    const error = useAppSelector(EnquiresSelectors.getEnquiryDetailsError);

    const enquiry = useAppSelector(EnquiresSelectors.getEnquiryDetails);

    const enquiryType = Number(searchParams.get('type'));
    const enquiryId = params.id;

    // const { maxIndex, totalRecords, data: customersData } = useAppSelector(CustomerSelectors.getCustomers);

    const isValidEnquiry = () => {
        if (!enquiryId || !enquiryType || Number.isNaN(enquiryType) || !EnquiryTypeEnum[enquiryType]) return false;
        return true;
    };

    useEffect(() => {
        if (isValidEnquiry()) {
            dispatch(Actions.enquiriesGetEnquiryDetailsAttempt({ id: enquiryId || '', type: enquiryType }));
        }
    }, []);

    useEffect(() => {
        if (enquiry) {
            let product: IProductDetails[] = [];

            switch (enquiry?.designPackage) {
                case 1:
                    product = [{
                        productId: '',
                        variantId: '',
                        name: 'Design Touch-Ups',
                        totalQuantity: 1,
                        price: enquiry.totalAmount?.toFixed(2) || '0.00',
                        total: enquiry.totalAmount || 0.00,
                        discountPercent: undefined,
                        discountFixed: undefined,
                        discountAmountApplied: 0.00,
                        disableQuantityEdit: true,
                        weight: 0,
                        remark: enquiry.message,
                    }];
                    break;
                case 2:
                    product = [{
                        productId: '',
                        variantId: '',
                        name: 'Premium package',
                        totalQuantity: 1,
                        price: enquiry.totalAmount?.toFixed(2) || '0.00',
                        total: enquiry.totalAmount || 0.00,
                        discountPercent: undefined,
                        discountFixed: undefined,
                        discountAmountApplied: 0.00,
                        disableQuantityEdit: true,
                        weight: 0,
                        remark: enquiry.message,
                    }];
                    break;
                case 3:
                    product = [{
                        productId: '',
                        variantId: '',
                        name: 'Creative Suite',
                        totalQuantity: 1,
                        price: enquiry.totalAmount?.toFixed(2) || '0.00',
                        total: enquiry.totalAmount || 0.00,
                        discountPercent: undefined,
                        discountFixed: undefined,
                        discountAmountApplied: 0.00,
                        disableQuantityEdit: true,
                        weight: 0,
                        remark: enquiry.message,
                    }];
                    break;

                default:
                    break;
            }

            dispatch(Actions.productSetIsTaxApplied(false));
            dispatch(Actions.productReplaceSelectedProducts(product));

            dispatch(Actions.customersSetFilters({
                searchQuery: enquiry?.companyName,
            }));
            dispatch(Actions.customersGetCustomersAttempt());
        }
    }, [enquiry]);

    if (!isValidEnquiry() || error) {
        let errorMessage = 'Sorry, we could not find this enquiry. It might have been deleted/moved.';

        if (error) errorMessage = error;

        return (
            <AdminLayout>
                <ErrorContainer>
                    <ErrorMessage error={errorMessage} />

                    <Button
                        label='Back to all enquiries'
                        onClick={NavActions.navToEnquiries}
                        style={{ width: 250 }}
                    />
                </ErrorContainer>
            </AdminLayout>
        );
    }

    if (loading) {
        return (
            <ErrorContainer>
                <LoadingIndicator />
            </ErrorContainer>
        );
    }

    const onDeletePressed = () => {
        dispatch(Actions.enquiriesSetDeleteConfirmationModalIsOpen(true));
    };

    const onResolvePressed = () => {
        dispatch(Actions.enquiriesSetResolveConfirmationModalIsOpen(true));
    };

    const getAddressText = (address: IAddress) => {
        const newAddress = `${address.street1} ${address.street2 ?? ''} ${address.postcode}, ${address.city}, ${address.state}, ${address.country}`;
        return newAddress;
    };

    const getDesignPackageText = (selected: DesignServicePackageEnum) => {
        switch (selected) {
            case 1:
                return 'Design Touch-Ups';
            case 2:
                return 'Premium Package';
            case 3:
                return 'Creative Suite';
            default:
                return 'Not Found';
        }
    };

    const renderActions = () => {
        if (!enquiry) return null;
        if (userInfo?.role === AuthRoleEnum.Executive) return null;

        return (
            <ButtonRow>
                <Button
                    label='Delete Message'
                    variant={ButtonVariant.warning}
                    style={{ width: 220 }}
                    onClick={onDeletePressed}
                />
                { enquiry.status !== EnquiryStatusEnum.Resolved && (
                    <Button
                        label='Resolve Message'
                        variant={ButtonVariant.default}
                        style={{ width: 220 }}
                        onClick={onResolvePressed}
                    />
                )}
            </ButtonRow>
        );
    };
    const renderEnquiry = () => {
        if (!enquiry) return null;

        return (
            <>
                <EnquiryContainer>
                    <Text variant={TextVariant.h2} color={theme.fontColor.primary}>
                        Customer Details
                    </Text>

                    <CustomerContainer>
                        <CustomerKeyContainer>
                            {enquiryType === 2 && (
                                <CustomerKeyText>Company Name:</CustomerKeyText>
                            )}
                            <CustomerKeyText>Name:</CustomerKeyText>
                            <CustomerKeyText>Email:</CustomerKeyText>
                            <CustomerKeyText>Phone No:</CustomerKeyText>
                            {enquiryType === 2 && (
                                <>
                                    <CustomerKeyText>Address:</CustomerKeyText>
                                    <CustomerKeyText>Design Package:</CustomerKeyText>
                                </>
                            )}

                        </CustomerKeyContainer>

                        <CustomerValueContainer>
                            {enquiryType === 2 && (
                                <Text>{enquiry.companyName}</Text>
                            )}
                            <Text>{enquiry.name}</Text>
                            <Text>{enquiry.email}</Text>
                            <Text>{enquiry.phoneNumber}</Text>
                            {enquiryType === 2 && (
                                <>
                                    <Text>{enquiry.address ? getAddressText(enquiry.address) : ''}</Text>
                                    <Text>{enquiry.designPackage ? getDesignPackageText(enquiry.designPackage) : ''}</Text>
                                </>
                            )}
                        </CustomerValueContainer>
                    </CustomerContainer>

                    <Separator />

                    <MessageTitleContainer>
                        <Text variant={TextVariant.h2}>
                            Message
                        </Text>
                        <BadgeContainer>
                            <StatusBadge variant={EnquiryStatusEnum[enquiry.status] as BadgeVariant} />
                        </BadgeContainer>
                    </MessageTitleContainer>

                    <MessageContainer>
                        <CustomerKeyText>
                            {enquiry.subject}
                        </CustomerKeyText>

                        <Text>
                            {enquiry.message}
                        </Text>

                        {enquiryType === 2 && (
                            <div className='flex'>
                                <CustomerKeyText>
                                    Total:
                                </CustomerKeyText>
                                {' '}
                                <Text>
                                    {formatCurrency(enquiry.currency as CurrencyEnum)}
                                    {enquiry.totalAmount}
                                </Text>
                            </div>
                        )}
                    </MessageContainer>
                </EnquiryContainer>
                {renderActions()}
            </>
        );
    };

    const onConvert = () => {
        NavActions.navToAddNewOrder();
    };

    const renderConvertButton = () => {
        if (!enquiry) return null;
        if (userInfo?.role === AuthRoleEnum.Executive) return null;
        if (enquiry.status === EnquiryStatusEnum.Resolved) return null;
        return (
            <ButtonContainer>
                <ButtonWrapper>
                    <Button
                        variant={ButtonVariant.primary}
                        label='Convert To Order'
                        onClick={onConvert}
                    />
                </ButtonWrapper>
            </ButtonContainer>
        );
    };

    let pageTitle;

    if (Number(enquiryType) === EnquiryTypeEnum.HomePage) pageTitle = 'Home Page Enquiry';
    else pageTitle = 'Design Services Enquiry';

    return (
        <AdminLayout>
            <div className='flex flex-row'>
                <PageHeader withBackButton>
                    {pageTitle}
                </PageHeader>
                {enquiryType === 2 && (
                    renderConvertButton()
                )}
            </div>

            {renderEnquiry()}

            <ConfirmResolveModal
                enquiryId={enquiryId}
                enquiryType={enquiry?.type}
                enquirySubject={enquiry?.subject}
            />
            <ConfirmDeleteModal
                enquiryId={enquiryId}
                enquiryType={enquiry?.type}
                enquirySubject={enquiry?.subject}
            />
        </AdminLayout>
    );
};

const ErrorContainer = styled.div`
    height: 400px;
    width: 100%;

    padding: 32px;

    background-color: white;
    border-radius: 15px;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    text-align: center;
`;

const EnquiryContainer = styled.div`
    border-radius: 15px;
    margin-top: 20px;

    padding: 26px 40px;

    background-color: white;
`;

const CustomerContainer = styled.div`
    display: flex;
    flex-direction: row;

    margin-top: 20px;
`;

const CustomerKeyContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;

    width: 160px;
`;

const CustomerKeyText = styled(Text)`
    font-weight: 700;
    color: ${theme.fontColor.primary};
`;

const CustomerValueContainer = styled.div`
    display: flex;
    flex-direction: column;

    gap: 6px;
`;

const Separator = styled.div`
    height: 1px;

    margin: 26px -40px;

    background-color: ${theme.colors.border};
`;

const MessageTitleContainer = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
`;

const BadgeContainer = styled.div`
    margin-left: 24px;
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;

    margin-top: 22px;

    gap: 6px;
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;

    gap: 8px;

    margin-top: 24px;
`;

const ButtonWrapper = styled.div`
    width: 15%;
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
`;

export default EnquiryDetails;
