import React from 'react';

import { isEmpty } from 'lodash';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import { useAppSelector } from 'redux/Hooks';
import ProductSelectors from 'redux/slices/products/Selectors';

import { IOrder, IOrderProduct } from 'entities/order';
import { EPrintSide, IProductSize } from 'entities/products';

import TabContainer from './TabContainer';

interface ComponentProps {
    orderDetails?: IOrder | null;
    loading?: boolean;
    error?: string;
}

interface ProductContentProps {
    label: string;
    printDetails: {
        type?: string;
        fit?: string;
        fabric?: string;
        sleeve?: string;
        color?: string;
    };
    printMethods?: {
        side: number,
        printCustomId: string,
        printVariantId: string,
        pricePerUnit: number,
        printMethod?: string,
        block?: string,
        colorCount?: string,
    }[],
    deliveryRemark?: string;
    sizeBreakdown?: IProductSize;
    totalQuantity?: number;
    weight?: number;
    remark?: string;
}

const ProductDetails: React.FC<ComponentProps> = (props: ComponentProps) => {
    const { orderDetails, loading, error } = props;
    if (!orderDetails?.products) return null;

    const productDetails: ProductContentProps[] = orderDetails?.products.map((product: IOrderProduct) => {
        const productSize = product.quantities?.flatMap((item) => {
            return {
                size: item.size,
                quantity: item.quantity,
            };
        });

        const newSize = productSize?.reduce((acc, item) => {
            return { ...acc, [item.size as string]: item.quantity };
        }, {}); // Initial accumulator (empty object)

        return {
            label: product.productName ?? 'Product Name Here',
            printDetails: {
                type: product.productVariant?.type,
                fit: product.productVariant?.fit,
                fabric: product.productVariant?.style,
                sleeve: product.productVariant?.sleeve,
                color: product.productVariant?.color,
            } || {},
            printMethods: product.printMethods,
            // deliveryRemark: product.deliveryRemark,
            sizeBreakdown: newSize,
            totalQuantity: product.finalQuantity,
            remark: product.remark,
        };
    });

    const productContent = (prop: ProductContentProps) => {
        const { printDetails, printMethods, deliveryRemark, sizeBreakdown, totalQuantity, weight, remark } = prop;
        const { type, fit, fabric, sleeve, color } = printDetails;

        const renderPrintMethod = () => {
            if (!printMethods) return null;

            return printMethods.map((item, index) => {
                return (
                    <PrintDetailLabelContainer key={`${item.printVariantId} - ${index + 1}`}>
                        {/* <a href='/'>Download</a>  // ? later if need link */}
                        <div>{`${EPrintSide[item.side]}: `}</div>
                        <div style={{ color: '#42564EA6' }}>{`${item.printMethod},${item.block}`}</div>
                    </PrintDetailLabelContainer>
                );
            });
        };
        return (
            <ProductContent>
                <ProductLabel>{prop.label}</ProductLabel>
                <Text variant={TextVariant.h2}>Print Detail</Text>
                <PrintDetailContainer>
                    {
                        !type && !fit && !fabric && !sleeve && !color && (
                            <PrintDetailLabelContainer>
                                <div>
                                    No print details
                                </div>
                            </PrintDetailLabelContainer>
                        )
                    }
                    {type && (
                        <PrintDetailLabelContainer>
                            <div>Type:</div>
                            <div style={{ color: '#42564EA6' }}>{type ?? 'N/A'}</div>
                        </PrintDetailLabelContainer>
                    )}
                    {fit && (
                        <PrintDetailLabelContainer>
                            <div>Fit:</div>
                            <div style={{ color: '#42564EA6' }}>{fit ?? 'N/A'}</div>
                        </PrintDetailLabelContainer>
                    )}
                    {fabric && (
                        <PrintDetailLabelContainer>
                            <div>Fabric:</div>
                            <div style={{ color: '#42564EA6' }}>{fabric ?? 'N/A'}</div>
                        </PrintDetailLabelContainer>
                    )}
                    {sleeve && (
                        <PrintDetailLabelContainer>
                            <div>Sleeve:</div>
                            <div style={{ color: '#42564EA6' }}>{sleeve ?? 'N/A'}</div>
                        </PrintDetailLabelContainer>
                    )}
                    {color && (
                        <PrintDetailLabelContainer>
                            <div>Color:</div>
                            <div style={{ color: '#42564EA6' }}>{color ?? 'N/A'}</div>
                        </PrintDetailLabelContainer>
                    )}
                    <hr />
                    {renderPrintMethod()}
                </PrintDetailContainer>
                {remark && (
                    <DeliveryRemarkContainer>
                        <div>Remark:</div>
                        <div style={{ color: '#42564EA6' }}>{remark}</div>
                    </DeliveryRemarkContainer>
                )}
                {deliveryRemark && (
                    <DeliveryRemarkContainer>
                        <div>Delivery Remark:</div>
                        <div style={{ color: '#42564EA6' }}>{deliveryRemark}</div>
                    </DeliveryRemarkContainer>
                )}
                {((printMethods && printMethods?.length > 0) || deliveryRemark) ? <hr /> : null}
                <Text variant={TextVariant.h2}>Size Breakdown</Text>
                <TableContainer>
                    <table style={{ width: '100%', tableLayout: 'fixed' }}>
                        <thead>
                            <TableHeaderRow>
                                {sizeBreakdown && Object.keys(sizeBreakdown).map((label, index) => (
                                    <TableCell key={`${label}-${index + 1}`}>{label}</TableCell>
                                ))}
                                <TableCell>Total</TableCell>
                            </TableHeaderRow>
                        </thead>
                        <tbody>
                            <tr>
                                {sizeBreakdown && Object.values(sizeBreakdown).map((value, index) => (
                                    <TableCell key={`${value}-${index + 1}`}>{value}</TableCell>
                                ))}
                                <TableCell>{totalQuantity}</TableCell>
                            </tr>
                        </tbody>
                    </table>
                </TableContainer>
            </ProductContent>
        );
    };

    const generateTabs = (data: ProductContentProps[]) => {
        return data.map(item => ({
            label: item.label,
            content: productContent(item),
        }));
    };

    const tabs = generateTabs(productDetails);

    return (
        <TabContainer
            tabs={tabs}
            loading={loading}
            error={error}
        />
    );
};

const ProductContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const ProductLabel = styled.div`
    font-size: 28px;
    border-bottom: 1px solid #42564E33;
    padding-bottom: 15px;
`;

const PrintDetailContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const PrintDetailLabelContainer = styled.div`
    display: flex;
    gap: 8px;
`;

const DeliveryRemarkContainer = styled.div`
    background-color: #EEF2EF;
    padding: 20px;
    border-radius: 10px;
`;

const TableContainer = styled.div`
    border-radius: 12px;
    overflow: hidden;
`;

const TableHeaderRow = styled.tr`
    background-color: #EEF2F0;
`;

const TableCell = styled.td`
    text-align: center;
    padding: 10px 0;
`;

export default ProductDetails;
