import { call, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import { CreatePaymentPayload } from 'redux/slices/order/types';
import { SagaWatcherReturnType } from 'sagas/types';

import NavActions from 'lib/NavActions';

export default function* watchCreatePayment(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderCreatePaymentAttempt', handleCreatePayment, api);
}
function* handleCreatePayment(api: OrderGateway, data: CreatePaymentPayload) {
    const authToken = yield* select((state) => state.auth.authToken);

    const response = yield* call([api, api.createPayment], {
        authToken,
        ...data.payload,
    });

    if (response.status === GatewayResponseStatus.Success) {
        NavActions.navToOrderDetails(data.payload.orderId, true);
    }
}
