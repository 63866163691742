import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import EnquiriesGateway from 'api/Enquiries';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import Selectors from 'redux/slices/enquiries/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetEnquiries(api: EnquiriesGateway): SagaWatcherReturnType {
    yield takeEvery('enquiries/enquiriesGetEnquiriesAttempt', handleGetEnquiries, api);
}
function* handleGetEnquiries(api: EnquiriesGateway) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const enquiriesFilter = yield* select(Selectors.getEnquiriesFilters);

    const { currentPage, dateStart, dateEnd, enquiryStatus, searchQuery, enquiryType } = enquiriesFilter;

    let response;

    if (enquiriesFilter.dateStart === '') {
        response = yield* call([api, api.getEnquiries], {
            authToken,
            currentPage,
            searchQuery,
            type: enquiryType,
            status: enquiryStatus,
        });
    } else {
        response = yield* call([api, api.getEnquiries], {
            authToken,
            currentPage,
            searchQuery,
            dateStart,
            dateEnd,
            type: enquiryType,
            status: enquiryStatus,
        });
    }

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.enquiriesGetEnquiriesFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.enquiriesGetEnquiriesSuccess(response.data));
    }
}
