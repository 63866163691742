import { EPrintSide, IPrintMethod, IProductVariant } from 'entities/products';
import { IQuotationProductQuantity } from 'entities/quotations';
import { CurrencyEnum } from 'entities/supplier';

type Status = 'Success' | 'Failed';

const Auth = {
    storeAuthToken: (authToken: string): void => {
        localStorage.setItem('authToken', authToken);
    },
    storeRefreshToken: (refreshToken: string): void => {
        localStorage.setItem('refreshToken', refreshToken);
    },
    getAuthToken: (): string | null => {
        return localStorage.getItem('authToken');
    },
    getRefreshToken: (): string | null => {
        return localStorage.getItem('refreshToken');
    },
    clearAuthToken: (): void => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
    },

    storeUserEmail: (email: string): void => {
        localStorage.setItem('email', email);
    },
    storeUserName: (name: string): void => {
        localStorage.setItem('name', name);
    },
    storeUserRole: (role: number): void => {
        localStorage.setItem('role', role.toString());
    },
    storeUserPermissions: (permissions: number[]): void => {
        localStorage.setItem('permissions', permissions.toString());
    },
    getUserEmail: (): string | null => {
        return localStorage.getItem('email');
    },
    getUserName: (): string | null => {
        return localStorage.getItem('name');
    },
    getUserRole: (): string | null => {
        return localStorage.getItem('role');
    },
    getUserPermissions: (): string | null => {
        return localStorage.getItem('permissions');
    },
    clearUserInfo: (): void => {
        localStorage.removeItem('email');
        localStorage.removeItem('name');
        localStorage.removeItem('role');
        localStorage.removeItem('permissions');
    },
    storeExpiresAt: (expireAt: string): void => {
        localStorage.setItem('expireAt', expireAt);
    },
    getExpiresAt: (): string | null => {
        return localStorage.getItem('expireAt');
    },
    clearExpiresAt: (): void => {
        localStorage.removeItem('expireAt');
    },
};

const LocalStorage = {
    getItem<T>(key: string): T | null {
        const storageItem = localStorage.getItem(key);

        if (!storageItem) return null;

        return JSON.parse(storageItem);
    },
    setItem: (key: string, item: unknown | null): Status => {
        if (!item) return 'Failed';
        const parse = JSON.stringify(item);

        localStorage.setItem(key, parse);
        return 'Success';
    },
    removeItem: (key: string): void => {
        localStorage.removeItem(key);
    },
};

type GetHeadersReturnType = {
    headers: {
        Authorization: string;
    }
    timeout?: number;
}

const getHeaders = (authToken: string, timeout?: number): GetHeadersReturnType => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
    timeout,
});

const simulateApi = (response: unknown): Promise<unknown> => {
    return new Promise(res => {
        setTimeout(() => {
            res(response);
        }, 500);
    });
};

const Validators = {
    validateEmail: (email: string): boolean => {
        const emailRegex = /^[\w.]+@([\w-]+\.)+[\w-]{2,10}$/g;
        return emailRegex.test(email);
    },
    validatePhoneNumber: (phone: string): boolean => {
        const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/g;
        return phoneRegex.test(phone);
    },
};

const Formatter = {
    formatPrice: (price: number): string => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return formatter.format(price);
    },
    formatCurrency: (currency: CurrencyEnum): string => {
        if (currency === CurrencyEnum.SingaporeDollar) return 'S$';
        if (currency === CurrencyEnum.USDollar) return '$';
        return 'RM';
    },

    formatVariantLabel: (variant: Omit<IProductVariant, 'id' | 'productId' | 'variantCode'> | null): string => {
        if (!variant) return '';

        const { fit, type, sleeve, style, color } = variant;

        const nonNullValues = [fit, type, sleeve, style, color].filter(value => value);

        return nonNullValues.join(', ').trim()
            .replace(/,,+/g, ',');
    },

    formatSizeLabel: (size: IQuotationProductQuantity[]): string => {
        let sizeLabels: string[] = [];

        size.forEach(item => {
            sizeLabels = [...sizeLabels, `${item.size}:${item.quantity}`];
        });

        return sizeLabels.join(' | ');
    },

    formatPrintMethodLabel: (printMethodLabel: IPrintMethod): string => {
        if (!printMethodLabel) return '';
        const { side, printMethod, block, colorCount } = printMethodLabel;

        const nonNullValues = [EPrintSide[side], printMethod, block, colorCount].filter(value => value);

        return nonNullValues.join(', ').trim()
            .replace(/,,+/g, ',');
    },
};

export default {
    Auth,
    LocalStorage,
    getHeaders,
    simulateApi,

    Validators,
    Formatter,
};
