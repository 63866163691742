import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import DropdownButtonForActions from 'components/atoms/DropdownButtonForActions';
import ErrorMessage from 'components/atoms/ErrorMessage';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import PageHeader from 'components/atoms/PageHeader';
import StatusBadge from 'components/atoms/StatusBadge';
import { BadgeVariant } from 'components/atoms/variants/BadgeVariant';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import AdminLayout from 'components/Layout/AdminLayout';
import ProductViewer from 'components/molecules/ProductViewer';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';
import PurchaseOrderSelectors from 'redux/slices/purchaseOrder/Selectors';
import SupplierSelectors from 'redux/slices/suppliers/Selectors';

import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';
import { AuthRoleEnum } from 'entities/auth';
import { ICompanyDetails } from 'entities/company';
import { IProductDetails } from 'entities/products';
import { PurchaseOrderStatusEnum } from 'entities/purchaseOrders';
import { CurrencyEnum, ISupplierDetails } from 'entities/supplier';

import DeletePoModal from './components/DeletePoModal';
import PurchaseOrderDetails from './components/PurchaseOrderDetails';
import SendPoModal from './components/SendPoModal';

const ViewPurchaseOrder = (): JSX.Element => {
    const params = useParams();
    const poId = params.id;

    const {
        formatVariantLabel,
        formatSizeLabel,
        formatPrintMethodLabel,
    } = Utils.Formatter;

    const userInfo = useAppSelector(AuthSelectors.getUserInfo);

    const loading = useAppSelector(PurchaseOrderSelectors.getPurchaseOrderDetailsAttempting);
    const error = useAppSelector(PurchaseOrderSelectors.getPurchaseOrderDetailsError);
    const poDetails = useAppSelector(PurchaseOrderSelectors.getPurchaseOrderDetails);
    const suppDetails = useAppSelector(SupplierSelectors.getSupplierDetails);

    const downloadPoLoading = useAppSelector(PurchaseOrderSelectors.getDownloadPurchaseOrderAttempting);

    const dispatch = useAppDispatch();

    const [taxPercent, setTaxPercent] = useState<number>(0);

    useEffect(() => {
        if (poId) {
            dispatch(Actions.poGetPurchaseOrderDetailsAttempt({ id: poId }));
            dispatch(Actions.productsGetPrintMethodAttempt({ index: -1 }));
        } else {
            NavActions.navToPurchaseOrders();
        }
    }, []);

    useEffect(() => {
        if (poDetails) {
            dispatch(Actions.companyGetCompanyDetailsAttempt({ id: poDetails.companyAccountId }));
        }
        if (poDetails) {
            dispatch(Actions.supplierGetSupplierDetailsAttempt({ id: poDetails.supplierId }));
        }
    }, [poDetails]);

    useEffect(() => {
        const taxPrice = poDetails?.tax ?? 0;
        const totalPrice = poDetails?.finalPrice ?? 0;

        if (taxPrice === 0) {
            setTaxPercent(0);
            return;
        }

        if (totalPrice === 0) {
            setTaxPercent(0);
            return;
        }
        const priceBeforeTax = totalPrice - taxPrice;

        setTaxPercent((taxPrice / priceBeforeTax) * 100);
    }, [poDetails?.tax]);

    const productTableHeaders = [
        { header: 'Name' },
        { header: 'Quantity' },
        { header: 'Price Per Unit' },
        { header: 'Price After Discount' },
        { header: 'Discount Total' },
        { header: 'Total' },
    ];

    const NewSelectedProducts = (): IProductDetails[] => {
        if (!poDetails?.products) return [];
        const selectedProducts = poDetails?.products.map(product => {
            return {
                productId: product.productId,
                variantId: product.variantId,
                name: product.productName ?? 'Product Name Here',
                variant: {
                    type: product.productVariant ? product.productVariant.type : '',
                    fit: product.productVariant ? product.productVariant.fit : '',
                    sleeve: product.productVariant ? product.productVariant.sleeve : '',
                    style: product.productVariant ? product.productVariant.style : '',
                    color: product.productVariant ? product.productVariant.color : '',
                },
                variantText: formatVariantLabel(product.productVariant),
                sizeText: formatSizeLabel(product.quantities),
                printMethodText: product.printMethods.map((method) => {
                    return formatPrintMethodLabel({
                        side: method.side,
                        printMethod: method.printMethod ?? '',
                        block: method.block ?? '',
                        colorCount: method.colorCount ?? '',
                    });
                }),
                totalQuantity: product.finalQuantity,
                quantities: product.quantities.map(item => ({
                    id: item.productPriceId,
                    size: item.size,
                    minimumQuantity: item.quantity,
                    quantity: item.quantity,
                    pricePerUnit: 10,
                })),
                discountPercent: product.discountPercent ?? undefined,
                discountFixed: product.discountFixed ?? undefined,
                printMethods: product.printMethods,
                price: product.totalPricePerUnit?.toFixed(2) || '0.00',
                total: product.finalProductPrice,
                disableQuantityEdit: true,
                weight: product.weight,
                remark: product.remark,
            };
        });

        const customLineProducts = poDetails?.customLineProducts.map(product => {
            return {
                productId: '',
                variantId: '',
                name: product.productName ?? 'Product Name Here',
                totalQuantity: product.finalQuantity,
                price: product.totalPricePerUnit?.toFixed(2) || '0.00',
                total: product.finalProductPrice,
                discountPercent: product.discountPercent ?? undefined,
                discountFixed: product.discountFixed ?? undefined,
                disableQuantityEdit: true,
                weight: product.weight,
                remark: product.remark,
            };
        });

        const combinedSelectedProduct = [...selectedProducts, ...customLineProducts];
        return combinedSelectedProduct;
    };

    const poStatusVariant = (poStatus: PurchaseOrderStatusEnum) => {
        if (poStatus === PurchaseOrderStatusEnum.Pending) {
            return BadgeVariant.Pending;
        }
        if (poStatus === PurchaseOrderStatusEnum.Delivered) {
            return BadgeVariant.Delivered;
        }
        return BadgeVariant.Pending;
    };

    const onSend = () => {
        dispatch(Actions.poSetSendPoModalIsOpen(true));
    };

    const onDownload = () => {
        if (poId && poDetails) dispatch(Actions.poDownloadPurchaseOrderAttempt({ id: poId }));
    };

    const renderBody = () => {
        if (loading) {
            return (
                <ErrorContainer>
                    <LoadingIndicator />
                </ErrorContainer>
            );
        }

        if (error) {
            return (
                <ErrorContainer>
                    <ErrorMessage error='Sorry, we could not find this purchase order. It might have been deleted/moved.' />

                    <Button
                        label='Back to all purchase orders'
                        onClick={NavActions.navToPurchaseOrders}
                        style={{ width: 250 }}
                    />
                </ErrorContainer>
            );
        }

        if (!poDetails) {
            return null;
        }

        const {
            poNumber, createdByName, companyName, companyAlias, companyAddress, createdAt, poDate, companyNumber,
            supplierName, supplierAddress, supplierContactPersonName, supplierContactPersonNumber, deliveryAddress,
            deliveryCompanyName,
        } = poDetails;

        const companyDetails: Omit<ICompanyDetails, 'id'> = {
            name: companyName,
            alias: companyAlias,
            address: companyAddress,
            phoneNumber: companyNumber,
            createdAt,
        };

        const supplierDetails: Partial<ISupplierDetails> = {
            supplierName,
            supplierAddress,
            contactPersonName: supplierContactPersonName,
            contactPersonNumber: supplierContactPersonNumber,
            currency: suppDetails?.currency,
        };

        return (
            <>
                <PurchaseOrderDetails
                    companyDetails={companyDetails}
                    supplierDetails={suppDetails ?? supplierDetails}
                    deliveryCompanyName={deliveryCompanyName}
                    deliveryAddress={deliveryAddress}
                    purchaseOrderNo={poNumber}
                    poDate={poDate}
                    preparedBy={createdByName}
                    createdAt={createdAt}
                />
                <BottomContainer>
                    <ProductViewer
                        tableHeaders={productTableHeaders}
                        currency={supplierDetails?.currency || CurrencyEnum.MalaysianRinggit}
                        viewData={{
                            selectedProducts: NewSelectedProducts(),
                            discountPercent: poDetails?.discountPercent?.toString(),
                            discountFixed: poDetails?.discountFixed?.toFixed(2),
                            shippingCost: poDetails?.shippingFee.toFixed(2) ?? '0.00',
                            taxPrice: poDetails?.tax.toFixed(2) ?? '0.00',
                        }}
                        taxPercent={Number(taxPercent.toFixed(0))}
                    />
                </BottomContainer>
            </>
        );
    };

    const renderPageHeader = () => {
        if (loading || !poDetails) {
            return (
                <PageTitle>
                    Purchase Order
                </PageTitle>
            );
        }

        return (
            <PageTitle>
                {`Purchase Order ${poDetails?.poNumber}`}
                <span>
                    <StatusBadge variant={poStatusVariant(poDetails.status)} />
                </span>
            </PageTitle>
        );
    };

    const renderActions = () => {
        if (!poDetails) return null;
        if (userInfo?.role === AuthRoleEnum.Executive || userInfo?.role === AuthRoleEnum.Supplier) return null;
        if (poDetails.status === PurchaseOrderStatusEnum.Delivered) return null;
        return (
            <DropdownButtonForActions
                options={[{
                    label: 'Send PO',
                    onClick: onSend,
                }, {
                    label: 'Edit',
                    onClick: () => NavActions.navToEditPurchaseOrder(poId || ''),
                }, {
                    label: 'Delete',
                    onClick: () => dispatch(Actions.poSetConfirmDeleteModalIsOpen(true)),
                }]}
            />

        );
    };

    return (
        <AdminLayout>
            <HeaderContainer status={poDetails?.status}>
                <PageHeader withBackButton>
                    {renderPageHeader()}
                </PageHeader>
                <ActionRow>
                    {renderActions()}
                    <Button
                        loading={downloadPoLoading}
                        variant={ButtonVariant.secondary}
                        label='Download'
                        onClick={onDownload}
                    />
                </ActionRow>
            </HeaderContainer>
            {renderBody()}

            <SendPoModal
                poDetails={poDetails}
            />
            <DeletePoModal
                poDetails={poDetails}
            />
        </AdminLayout>
    );
};

const HeaderContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => prop !== 'status',
}) <{ status?: PurchaseOrderStatusEnum }>`
    display: flex;
    justify-content: space-between;
    align-items: center;

    #actions {
        width: ${props => (props.status === PurchaseOrderStatusEnum.Pending ? '50%' : '30%')};
        margin-top: 2rem;
        padding-inline: 1rem;
        display: flex;
        gap: 1rem;
    }
`;

const PageTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const BottomContainer = styled.div`
    display: flex;
    width: 100%;

    padding: 1.5rem 2rem;

    border-radius: 15px;

    background-color: white;
`;

const ErrorContainer = styled.div`
    height: 400px;
    width: 100%;

    padding: 32px;

    background-color: white;
    border-radius: 15px;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    text-align: center;
`;

const ActionRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 2rem;

    gap: 20px;
`;

export default ViewPurchaseOrder;
